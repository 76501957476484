import React, {useEffect, useRef, useState} from 'react';
import {Modal} from 'antd';

const useModal = ({width, bodyStyle = {}} = {}) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(null);
  const modalRef = useRef();

  useEffect(() => {
    if (modalRef) {
      modalRef.current = {
        open: () => {
          setVisible(true);
        },
        close: () => {
          setVisible(false);
        },
        set: ({elem}) => {
          setContent(elem);
        },
      };
    }
  }, [modalRef, setVisible]);

  const modalElem = (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0, ...bodyStyle}}
      maskStyle={{backgroundColor: 'rgba(0,0,0,0.75)'}}
      width={width || 500}
      visible={visible}
      onCancel={() => {
        modalRef.current.close();
        setVisible(false);
      }}>
      {content}
    </Modal>
  );
  return {
    modalElem: modalElem,
    modalRef: modalRef,
  };
};

export default useModal;
