// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-generators-admin-resource-index-js": () => import("./../../../src/Generators/AdminResource/index.js" /* webpackChunkName: "component---src-generators-admin-resource-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/Templates/About/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-admin-articles-index-js": () => import("./../../../src/Templates/AdminArticles/index.js" /* webpackChunkName: "component---src-templates-admin-articles-index-js" */),
  "component---src-templates-admin-image-list-index-js": () => import("./../../../src/Templates/AdminImageList/index.js" /* webpackChunkName: "component---src-templates-admin-image-list-index-js" */),
  "component---src-templates-admin-index-js": () => import("./../../../src/Templates/Admin/index.js" /* webpackChunkName: "component---src-templates-admin-index-js" */),
  "component---src-templates-admin-settings-index-js": () => import("./../../../src/Templates/AdminSettings/index.js" /* webpackChunkName: "component---src-templates-admin-settings-index-js" */),
  "component---src-templates-anonymous-order-index-js": () => import("./../../../src/Templates/AnonymousOrder/index.js" /* webpackChunkName: "component---src-templates-anonymous-order-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/Templates/Contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-custom-checkout-index-js": () => import("./../../../src/Templates/CustomCheckout/index.js" /* webpackChunkName: "component---src-templates-custom-checkout-index-js" */),
  "component---src-templates-custom-product-detail-index-js": () => import("./../../../src/Templates/CustomProductDetail/index.js" /* webpackChunkName: "component---src-templates-custom-product-detail-index-js" */),
  "component---src-templates-faq-index-js": () => import("./../../../src/Templates/Faq/index.js" /* webpackChunkName: "component---src-templates-faq-index-js" */),
  "component---src-templates-forgot-password-confirm-page-js": () => import("./../../../src/Templates/ForgotPassword/ConfirmPage.js" /* webpackChunkName: "component---src-templates-forgot-password-confirm-page-js" */),
  "component---src-templates-forgot-password-request-page-js": () => import("./../../../src/Templates/ForgotPassword/RequestPage.js" /* webpackChunkName: "component---src-templates-forgot-password-request-page-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/Templates/News/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-online-menu-index-js": () => import("./../../../src/Templates/OnlineMenu/index.js" /* webpackChunkName: "component---src-templates-online-menu-index-js" */),
  "component---src-templates-order-index-js": () => import("./../../../src/Templates/Order/index.js" /* webpackChunkName: "component---src-templates-order-index-js" */),
  "component---src-templates-order-list-index-js": () => import("./../../../src/Templates/OrderList/index.js" /* webpackChunkName: "component---src-templates-order-list-index-js" */),
  "component---src-templates-product-list-index-js": () => import("./../../../src/Templates/ProductList/index.js" /* webpackChunkName: "component---src-templates-product-list-index-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/Templates/Profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-register-confirm-page-js": () => import("./../../../src/Templates/Register/ConfirmPage.js" /* webpackChunkName: "component---src-templates-register-confirm-page-js" */),
  "component---src-templates-register-request-page-js": () => import("./../../../src/Templates/Register/RequestPage.js" /* webpackChunkName: "component---src-templates-register-request-page-js" */),
  "component---src-templates-social-login-index-js": () => import("./../../../src/Templates/SocialLogin/index.js" /* webpackChunkName: "component---src-templates-social-login-index-js" */)
}

