import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {withLoginRequired} from 'rev.sdk.js/Components/LoginRequired';
import SiteNavBar from '../Components/SiteNavBar';
import renderLoginRequired from './LoginRequiredUi';
import ProfileMenu, {ProfileTabs} from './ProfileMenu';

function ProfileLayout(props) {
  const {style = {}} = props;
  const [dimension] = useOutlet('dimension');
  const activePath = props.location.pathname.split('/')[2];
  const mobile = dimension.rwd === 'mobile';

  return (
    <Wrapper style={{...style}}>
      <div className="content">
        <div
          style={{display: 'flex', flexDirection: mobile ? 'column' : 'row'}}>
          {mobile ? (
            <ProfileTabs activePath={activePath} />
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <ProfileMenu activePath={activePath} />
            </div>
          )}

          <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            <div style={{padding: 'var(--basePadding)'}}>{props.children}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .content {
    max-width: var(--contentMaxWidth);
    min-height: var(--contentMinHeight);
    margin: 0 auto;
    padding: var(--basePadding);
  }
`;

export default withLoginRequired(ProfileLayout, {
  admin: false,
  SiteNavBar,
  renderLoginRequired,
});
