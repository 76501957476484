import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Checkbox, ConfigProvider, Empty, Select} from 'antd';
import * as AppActions from '../../AppActions';
import * as CartActions from '../../Actions/Cart';
import * as Algorithm from '../../Utils/VariantsUtil';
import {
  BREAK_POINTS,
  CART_ITEMS_LIMIT,
  LOGISTIC_TYPE,
  LOGISTIC_TYPE_DISPLAY,
} from '../../Domain/Constants';

function ProductVariants(props) {
  const {
    product,
    onNextConfig,
    onEditCart = null,
    config = null,
    disabled = false,
    rowStyle = {},
    specs = [],
  } = props;
  const [quantity, setQuantity] = React.useState(config?.qty || 1);
  const [internalSpec, setInternalSpec] = React.useState(config?.spec || null);
  const [logisticType, setLogisticType] = React.useState(
    config?.logistic_type || undefined,
  );
  const [values, setValues] = React.useState(
    config?.variants ||
      (product.variants || []).reduce((acc, variant) => {
        if (
          variant.type === 'single' &&
          Array.isArray(variant.choices) &&
          variant.choices[0]
        ) {
          acc.push({
            name: variant.name,
            choice: variant.choices[0].name,
          });
        }
        return acc;
      }, []),
  );

  React.useEffect(() => {
    if (config?.spec) {
      setInternalSpec(config.spec);
    }
  }, [config?.spec]);

  React.useEffect(() => {
    async function fetchData() {
      if (onNextConfig) {
        try {
          AppActions.setLoading(true);
          const itemConfig = {
            qty: quantity,
            variants: values,
            spec: internalSpec,
            logistic_type: logisticType,
          };
          const resp = await CartActions.calcPrice(
            product.id,
            itemConfig,
            product,
          );
          onNextConfig(itemConfig, resp);
        } catch (ex) {
          console.warn(ex);
        } finally {
          AppActions.setLoading(false);
        }
      }
    }

    fetchData();
  }, [onNextConfig, product, quantity, values, internalSpec, logisticType]);

  const logisticsTypeOptions = (
    product.allow_logistics_types || (logisticType ? [logisticType] : [])
  ).sort((a, b) => {
    const order = {
      [LOGISTIC_TYPE.icatFreeze]: 1,
      [LOGISTIC_TYPE.selfPick]: 2,
      [LOGISTIC_TYPE.postNormal]: 3,
      default: 1000,
    };
    return (order[a] || order.default) - (order[b] || order.default);
  });

  useEffect(() => {
    if (!logisticType) {
      setLogisticType(logisticsTypeOptions[0]);
    }
  }, [logisticType, logisticsTypeOptions]);

  return (
    <Wrapper>
      {(product.variants || []).map((variant) => {
        if (variant.type === 'bool') {
          return (
            <InputField key={variant.name} style={rowStyle}>
              <Checkbox
                disabled={disabled}
                checked={Algorithm.getBool({values, name: variant.name})}
                onChange={(evt) => {
                  Algorithm.setBool({
                    values,
                    setValues,
                    name: variant.name,
                    checked: evt.target.checked,
                  });
                }}>
                {variant.label}
              </Checkbox>
            </InputField>
          );
        } else if (variant.type === 'single') {
          return (
            <InputField key={variant.name} style={rowStyle}>
              <label>{variant.label}</label>
              <Select
                disabled={disabled}
                style={{width: 200}}
                value={Algorithm.getSingle({values, name: variant.name})}
                onChange={(value) => {
                  Algorithm.setSingle({
                    values,
                    setValues,
                    name: variant.name,
                    choice: value,
                  });
                }}>
                {variant.choices.map((option) => {
                  return (
                    <Select.Option key={option.name} value={option.name}>
                      {option.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </InputField>
          );
        }
        return null;
      })}

      <ConfigProvider renderEmpty={() => <Empty description="已售完" />}>
        <InputField style={rowStyle}>
          <label>出貨日期</label>
          <Select
            disabled={disabled}
            placeholder="請選擇出貨日期"
            value={internalSpec || undefined}
            dropdownMatchSelectWidth={false}
            style={{minWidth: 150}}
            onChange={(value) => {
              setInternalSpec(value);
            }}>
            {specs.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.display}
              </Select.Option>
            ))}
          </Select>
        </InputField>
      </ConfigProvider>

      <InputField style={rowStyle}>
        <label>購買數量</label>
        <Select
          disabled={onEditCart ? false : disabled}
          value={quantity}
          style={{width: 150}}
          onChange={async (value) => {
            if (onEditCart) {
              try {
                await onEditCart({
                  qty: value,
                  variants: values,
                  spec: internalSpec,
                });
              } catch (e) {
                return;
              }
            }
            setQuantity(value);
          }}>
          {new Array(product.limit || CART_ITEMS_LIMIT)
            .fill(0)
            .map((opt, key) => {
              const quantity = key + 1;
              return (
                <Select.Option key={key} value={quantity}>
                  {quantity}
                </Select.Option>
              );
            })}
        </Select>
      </InputField>

      <ConfigProvider renderEmpty={() => <Empty description="尚未開放" />}>
        <InputField style={rowStyle}>
          <label>物流方式</label>
          <Select
            disabled={disabled}
            placeholder="請選擇物流方式"
            value={logisticType}
            dropdownMatchSelectWidth={false}
            style={{minWidth: 150}}
            onChange={(value) => {
              setLogisticType(value);
            }}>
            {logisticsTypeOptions.map((t) => {
              const display = LOGISTIC_TYPE_DISPLAY[t];
              return (
                <Select.Option key={t} value={t}>
                  {display}
                </Select.Option>
              );
            })}
          </Select>
        </InputField>
      </ConfigProvider>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const InputField = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > label {
    margin-right: 10px;
  }

  @media screen and (max-width: ${BREAK_POINTS.xxs}px) {
    flex-direction: column;
  }
`;

export default ProductVariants;
