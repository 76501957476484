import React, {useCallback, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import ProductSpecsForm from './ProductSpecsForm';
import {
  LOGISTIC_STATUS,
  LOGISTIC_STATUS_DISPLAY,
  MEMBERSHIP,
  MEMBERSHIP_DISPLAY,
  ORDER_STATUS_DISPLAY,
  PAYMENT_STATUS,
  PAYMENT_STATUS_DISPLAY,
} from '../src/Domain/Constants';
import OrderInfo from '../src/Components/OrderInfo';
import CartItem from '../src/Components/CartItem';
import OrderExportCsvButton from '../src/Components/OrderExportCsvButton';
import {Button, Card, Form, Input, message} from 'antd';
import styled from 'styled-components';
import AntdAddressSetForm from 'rev.sdk.js/Components/AntdAddressSetForm';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../src/AppActions/index';
import AdminShowUserOrderButton from '../src/Components/AdminShowUserOrderButton';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import UserExportCsvButton from '../src/Components/UserExportCvsButton';

const CustomOrderFilter = (props) => {
  const {setQueryState, queryState} = props;
  const [filters, setFilters] = React.useState({
    items_name: '',
    delivery_date: '',
    created_from: '',
    created_to: '',
  });

  const onChange = (field) => async (e) => {
    const {value} = e.target;
    let _nextFilters = {...filters, [field]: value};
    setFilters(_nextFilters);
  };

  const onFilterButtonClick = async () => {
    let _extraQueries = {};

    function _isDateStringValueInvalid(value) {
      return (
        value &&
        (!/\d{4}-\d{2}-\d{2}/.test(value) ||
          !moment(value, 'YYYY-MM-DD').isValid())
      );
    }

    if (
      _isDateStringValueInvalid(filters['created_from']) ||
      _isDateStringValueInvalid(filters['created_to']) ||
      _isDateStringValueInvalid(filters['delivery_date'])
    ) {
      message.warn('請檢查欄位格式');
      return;
    }

    if (filters['items.name']) {
      _extraQueries['items.name'] = {
        $regex: filters['items.name'],
      };
    }

    if (filters['created_from']) {
      let _queryOper = '$gte';
      let _queryValue = moment(filters['created_from'], 'YYYY-MM-DD').valueOf();
      let _newFilter = {created: {[_queryOper]: _queryValue}};
      let _andQuery =
        queryState.extraQueries.$and?.filter(
          (ele) => !(ele.created && ele.created[_queryOper]),
        ) || [];
      _extraQueries['$and'] = [..._andQuery, _newFilter];
    }

    if (filters['created_to']) {
      let _queryOper = '$lte';
      let _queryValue = moment(filters['created_to'], 'YYYY-MM-DD')
        .add(1, 'days')
        .valueOf();
      let _newFilter = {created: {[_queryOper]: _queryValue}};
      let _andQuery =
        queryState.extraQueries.$and?.filter(
          (ele) => !(ele.created && ele.created[_queryOper]),
        ) || [];
      _extraQueries['$and'] = [..._andQuery, _newFilter];
    }

    if (filters['delivery_date']) {
      const start_date = moment(filters['delivery_date']).valueOf();
      const end_date = moment(filters['delivery_date'])
        .add(1, 'days')
        .valueOf();
      _extraQueries['delivery_date'] = {
        $gte: {$date: start_date},
        $lt: {$date: end_date},
      };
    }

    setQueryState((prev) => {
      const nextExtraQuery = {...prev.extraQueries, ..._extraQueries};
      const filterOutQueryKeys = Object.keys(prev.extraQueries)
        .map((q) => {
          if (!Object.keys(_extraQueries).find((qk) => qk === q)) {
            return q;
          } else {
            return null;
          }
        })
        .filter((k) => !!k);
      filterOutQueryKeys.forEach((k) => {
        delete nextExtraQuery[k];
      });
      return {
        ...prev,
        extraQueries: nextExtraQuery,
      };
    });
  };

  return (
    <div>
      <Input
        value={filters['item.name']}
        style={{marginBottom: 10}}
        placeholder="搜尋訂單內商品名稱"
        onChange={onChange('items.name')}
      />

      <Input
        value={filters['delivery_date']}
        style={{marginBottom: 10}}
        placeholder="搜尋訂單出貨日 YYYY-MM-DD"
        onChange={onChange('delivery_date')}
      />

      <Input
        value={filters['created_from']}
        style={{marginBottom: 10}}
        placeholder="訂單成立日（開始）YYYY-MM-DD"
        onChange={onChange('created_from')}
      />
      <Input
        value={filters['created_to']}
        style={{marginBottom: 10}}
        placeholder="訂單成立日（結束）YYYY-MM-DD"
        onChange={onChange('created_to')}
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
        }}>
        <Button type={'primary'} onClick={onFilterButtonClick}>
          確認
        </Button>
        <div style={{padding: '4px 0'}}>
          「無出貨日商品」請以訂單成立時間或商品名稱做搜尋
        </div>
      </div>
    </div>
  );
};

function UpdateOrderSection(props) {
  const {values, setValues} = props;
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!values) {
      return;
    }
    form.setFieldsValue({
      receiver_address: values.receiver_address,
      receiver_zip: values.receiver_zip,
      receiver_city: values.receiver_city,
      receiver_district: values.receiver_district,
      receiver_name: values.receiver_name,
      receiver_phone: values.receiver_phone,
    });
  }, [form, values]);

  const handleUpdateOrder = useCallback(
    async (data) => {
      try {
        AppActions.setLoading(true);
        await JStorage.updateDocument(
          'order',
          {order_number: values.order_number},
          {
            receiver_address: data.receiver_address,
            receiver_zip: data.receiver_zip,
            receiver_city: data.receiver_city,
            receiver_district: data.receiver_district,
            receiver_name: data.receiver_name,
            receiver_phone: data.receiver_phone,
          },
        );
        setValues((prev) => ({
          ...prev,
          receiver_address: data.receiver_address,
          receiver_zip: data.receiver_zip,
          receiver_city: data.receiver_city,
          receiver_district: data.receiver_district,
          receiver_name: data.receiver_name,
          receiver_phone: data.receiver_phone,
        }));
        setIsDirty(false);
        message.success('更新資訊成功');
      } catch (e) {
        message.error('更新資訊失敗');
      } finally {
        AppActions.setLoading(false);
      }
    },
    [setValues, values.order_number],
  );

  const handleRegenLogisticsOrder = useCallback(async () => {
    try {
      AppActions.setLoading(true);
      const {error = null} = await AppActions.createLogisticsOrder(
        values.order_number,
      );
      if (error) {
        throw new Error(error);
      }
      message.success('建立成功');
      try {
        await AppActions.delay(2000);
        const _order = await JStorage.fetchOneDocument('order', {
          order_number: values.order_number,
        });
        setValues((prev) => {
          return {
            ...prev,
            ..._order,
          };
        });
      } catch (e) {
        message.error('訂單刷新失敗，請重新整理頁面');
      }
    } catch (e) {
      message.error(e.message);
    } finally {
      AppActions.setLoading(false);
    }
  }, [setValues, values.order_number]);

  return (
    <Card>
      <h2 style={{fontSize: 20, marginBottom: 16}}>更改訂單資訊</h2>
      <Form
        form={form}
        initialValues={{
          receiver_address: '',
          receiver_zip: '',
          receiver_city: '',
          receiver_district: '',
          receiver_name: '',
          receiver_phone: '',
        }}
        onFinish={async (data) => {
          await handleUpdateOrder(data);
        }}
        onFinishFailed={() => {}}
        onFieldsChange={() => {
          const _isDirty = Object.keys(form.getFieldsValue()).some(
            (key) => form.getFieldsValue()[key] !== values[key],
          );
          setIsDirty(_isDirty);
        }}>
        <Form.Item name="receiver_name" label="收件人姓名">
          <Input
            type="text"
            value={form.getFieldValue('receiver_name')}
            onChange={(e) => {
              const {name, value} = e.target;
              form.setFieldsValue({[name]: value});
            }}
          />
        </Form.Item>
        <Form.Item name="receiver_phone" label="收件人電話">
          <Input
            type="text"
            value={form.getFieldValue('receiver_phone')}
            onChange={(e) => {
              const {name, value} = e.target;
              form.setFieldsValue({[name]: value});
            }}
          />
        </Form.Item>
        <AntdAddressSetForm form={form} name="receiver" />
        <Form.Item>
          <Button
            disabled={!isDirty}
            onClick={form.submit}
            type="primary"
            htmlType="button"
            style={{marginRight: 10}}>
            更新資訊
          </Button>
          {values?.logistic_detail?.error && (
            <Button
              disabled={isDirty}
              htmlType="button"
              onClick={handleRegenLogisticsOrder}>
              重建物流訂單
            </Button>
          )}
        </Form.Item>
      </Form>
    </Card>
  );
}

function CustomAdminOrderSection(props) {
  const {instance, values, setValues} = props;

  const extraHintText = React.useMemo(() => {
    if (instance) {
      if (instance.membership !== MEMBERSHIP.normal) {
        return instance[`cart_giveaway_hint_${instance.membership}`];
      }
    }

    return '';
  }, [instance]);

  return (
    <div
      style={{
        padding: 15,
        border: '1px solid #d6d6d6',
        margin: '10px 0',
        borderRadius: 4,
        maxWidth: 800,
      }}>
      {instance.items.map((cartItem, idx) => (
        <CartItem item={cartItem} itemIdx={idx} key={idx} disabled />
      ))}
      <Section>
        <div className="separate" />
        {instance.cart_giveaway_hint_normal && (
          <p
            style={{
              whiteSpace: 'pre-line',
              wordBreak: 'break-word',
              maxWidth: 300,
              marginLeft: 'auto',
              color: 'rgba(132, 110, 79, 1)',
              marginTop: 5,
            }}>{`${instance.cart_giveaway_hint_normal}`}</p>
        )}

        {extraHintText && (
          <p
            style={{
              whiteSpace: 'pre-line',
              wordBreak: 'break-word',
              maxWidth: 300,
              marginLeft: 'auto',
              color: 'rgba(132, 110, 79, 1)',
              marginTop: 5,
            }}>{`${extraHintText}`}</p>
        )}

        <div className="field">
          <div className="name">小計</div>
          <div className="value">$ {instance.subtotal}</div>
        </div>
        {instance.extra_items.map((e, i) => (
          <div className="field" key={i}>
            <div className="name">{e.name}</div>
            <div className="value">$ {e.amount}</div>
          </div>
        ))}
        <div className="field total">
          <div className="name">總金額</div>
          <div className="value">$ {instance.total}</div>
        </div>
      </Section>
      <OrderInfo
        order={{
          ...instance,
          ...values,
        }}
        isAdmin={true}
        footerEle={null}
      />
      <UpdateOrderSection
        instance={instance}
        values={values}
        setValues={setValues}
      />
    </div>
  );
}

const SpecialIntentionToFetchSpecsComponent = ({records}) => {
  /*
   * 此 component 不是用來 render ui 使用 ，
   * 是用來隱含獲取資料給 renderCustomCol 做使用的。
   * */
  const fetched = useRef(false);
  const [_allSpecsByProduct, setAllSpecsByProduct] = useOutlet(
    'AllSpecsByProduct',
  );
  const [_products, setProducts] = useOutlet('Products');
  const setSpecsByProduct = useOutletSetter('SpecsByProduct');

  useEffect(() => {
    if (records.length <= 0 || fetched.current) {
      return;
    }
    const _fetch = async () => {
      const allSpecs = records
        .reduce((acc, cur) => {
          acc = [...acc, ...(cur?.specs || [])];
          return acc;
        }, [])
        .map((id) => ({
          id,
        }));

      if (allSpecs.length <= 0) {
        return;
      }

      const {results: _specs} = await JStorage.fetchDocuments('spec', {
        $or: allSpecs,
      });

      let result = {};

      for (const p of records) {
        result[p.id] = [];
      }

      for (const s of _specs) {
        for (const p of records) {
          if (!!(p?.specs || []).includes(s.id)) {
            result[p.id].push(s);
          }
        }
      }

      setProducts(records);
      setAllSpecsByProduct(result);
    };

    _fetch().then(() => {
      fetched.current = true;
    });

    return () => {
      setAllSpecsByProduct(null);
      fetched.current = false;
    };
  }, [records, setAllSpecsByProduct, setProducts]);

  useEffect(() => {
    if (!_allSpecsByProduct || _products.lenth <= 0) {
      return;
    }
    const _fetch = async () => {
      try {
        AppActions.setLoading(true);
        const allSpecs = Object.values(_allSpecsByProduct).flat(1);
        const result = await AppActions.getProductSpecStockByPaymentStatus({
          spec_ids: allSpecs.map((s) => s.id),
          payment_status: PAYMENT_STATUS.codeGenerated,
        });

        const aggregatedUnPaid = result.reduce((acc, cur) => {
          if (acc[cur.spec_id]) {
            acc[cur.spec_id] += cur.stock;
          } else {
            acc[cur.spec_id] = cur.stock;
          }
          return acc;
        }, {});

        const mergedWithAllSpecs = allSpecs.map((s) => {
          const unPaidStockQuantity = aggregatedUnPaid[s.id] || null;
          return {
            ...s,
            ...{
              unsold_stock: unPaidStockQuantity ? unPaidStockQuantity : 0,
            },
          };
        });

        let r = {};
        for (const p of _products) {
          r[p.id] = [];
        }
        for (const s of mergedWithAllSpecs) {
          for (const p of _products) {
            if (!!(p?.specs || []).includes(s.id)) {
              r[p.id].push(s);
            }
          }
        }

        setSpecsByProduct(r);
      } catch (e) {
        console.log(e);
      } finally {
        AppActions.setLoading(false);
      }
    };
    _fetch().then((r) => 0);
  }, [_allSpecsByProduct, _products, setSpecsByProduct]);

  return <div />;
};

function renderCustomSection(props) {
  const {type, name, path, context} = props;

  if (type === 'form' && name === 'product') {
    const {
      position,
      instance,
      values,
      setValues,
      extValues,
      setExtValues,
    } = context;

    if (instance) {
      if (position === 'top') {
        return (
          <div
            style={{
              padding: 15,
              border: '1px solid #d6d6d6',
              margin: '10px 0',
              borderRadius: 4,
              maxWidth: 800,
            }}>
            <ProductSpecsForm
              instance={instance}
              values={values}
              updateProduct={setValues}
            />
          </div>
        );
      }

      return null;
    }
  } else if (type === 'form' && name === 'order') {
    if (context.instance) {
      if (context.position === 'bottom') {
        return <CustomAdminOrderSection {...context} />;
      }

      return null;
    }
  } else if (
    type === 'resource' &&
    path === '/admin/orders' &&
    context.position === 'middle'
  ) {
    return (
      <div style={{margin: '10px 0'}}>
        <CustomOrderFilter {...context} />
        <OrderExportCsvButton {...context} />
      </div>
    );
  } else if (
    type === 'resource' &&
    path === '/admin/products' &&
    context.position === 'middle'
  ) {
    return <SpecialIntentionToFetchSpecsComponent {...context} />;
  } else if (
    type === 'resource' &&
    path === '/admin/users' &&
    context.position === 'middle'
  ) {
    return (
      <div>
        <UserExportCsvButton {...context} style={{margin: '10px 0'}} />
      </div>
    );
  }

  return null;
}

const SpecsColumn = ({record}) => {
  const {id} = record;
  const [specsByProduct] = useOutlet('SpecsByProduct');

  if (!specsByProduct?.[id] || specsByProduct?.[id].length <= 0) {
    return null;
  } else {
    return (
      <div>
        {specsByProduct?.[id].map((s, idx) => {
          return (
            <div
              key={idx}
              style={{
                border: '1px solid rgba(20,20,20,0.2)',
                marginBottom: 5,
                padding: 5,
              }}>
              <div>規格名稱：{s.display}</div>
              <div>
                運送日期：
                {moment(s.delivery_date.$datetime).format('YYYY-MM-DD')}
              </div>
              <div>
                剩餘庫存/未付款庫存：{s.stock}/{s.unsold_stock}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

function renderCustomCol(props) {
  const {col, record} = props;
  if (col.customType === 'timestamp') {
    return moment(record[col.key]).format('YYYY-MM-DD HH:mm');
  } else if (col.customType === 'date') {
    return moment(record[col.key]).format('YYYY-MM-DD');
  } else if (col.customType === 'status') {
    return ORDER_STATUS_DISPLAY[record[col.key]] || '-';
  } else if (col.customType === 'payment_status') {
    return PAYMENT_STATUS_DISPLAY[record[col.key]] || '-';
  } else if (col.customType === 'logistic_status') {
    return (
      (record.logistic_status === LOGISTIC_STATUS.exception
        ? record?.logistic_detail?.RtnMsg
        : LOGISTIC_STATUS_DISPLAY[record.logistic_status]) || '-'
    );
  } else if (col.customType === 'delivery_date') {
    return moment(record[col.key]['$datetime']).format('YYYY-MM-DD');
  } else if (col.customType === 'membership') {
    return MEMBERSHIP_DISPLAY[record.membership] || '-';
  } else if (col.customType === 'user_order_list') {
    return <AdminShowUserOrderButton user={record} />;
  } else if (col.customType === 'specs') {
    return <SpecsColumn record={record} />;
  } else if (col.customType === 'order_items') {
    return (
      <div>
        {record.items.map((i) => (
          <div
            style={{
              border: '1px solid rgba(20,20,20,0.2)',
              marginBottom: 5,
              padding: 5,
              minWidth: 200,
            }}>
            <div>商品名稱：{i.name}</div>
            <div>規格名稱：{i.spec.display}</div>
          </div>
        ))}
      </div>
    );
  }
  return null;
}

const Section = styled.section`
  & > .separate {
    height: 1px;
    background-color: #846e4f;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  & > .field {
    padding: 8px 20px;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;

    & > .name {
      font-size: 14px;
    }

    & > .value {
      font-size: 20px;
      color: #846e4f;
      min-width: 150px;
      text-align: right;
    }
  }

  & > .field.total {
    background-color: #1e2f29;
    color: #fff;

    & > .value {
      color: #fff;
      font-size: 30px;
    }
  }
`;

export {renderCustomSection, renderCustomCol};
