import React from 'react';
import {navigate} from 'gatsby';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import {Menu, Tabs} from 'antd';

function ProfileTabs(props) {
  const {activePath} = props;
  const [actions] = useOutlet('actions');
  const [user] = useOutlet('user');
  const showResetPasswordModal = useOutletSetter('reset-password-modal');

  const isNormalUser = React.useMemo(() => user.data?.provider === 'default', [
    user,
  ]);

  function onTabClick(nextKey) {
    if (nextKey[0] === '/') {
      // this is a path, simply navigate to it
      actions.navigate(nextKey, {loading: 800});
      return;
    }

    if (nextKey === 'reset-password') {
      showResetPasswordModal({admin: false});
    } else if (nextKey === 'logout') {
      actions.logout();
      setTimeout(() => {
        actions.navigate('/', {loading: 800});
      }, 100);
    }
  }

  return (
    <Tabs activeKey={activePath} onChange={onTabClick} style={{margin: 10}}>
      <Tabs.TabPane key="/profile" tab="個人資訊" />
      <Tabs.TabPane key="/profile/orders" tab="我的訂單" />
      {isNormalUser && <Tabs.TabPane key="reset-password" tab="重設密碼" />}
      <Tabs.TabPane key="logout" tab="登出" />
    </Tabs>
  );
}

function ProfileMenu(props) {
  const [actions] = useOutlet('actions');
  const [user] = useOutlet('user');
  const showResetPasswordModal = useOutletSetter('reset-password-modal');
  const {activePath} = props;
  const selectedKeys = [activePath ? activePath : 'info'];
  const isNormalUser = React.useMemo(() => user.data?.provider === 'default', [
    user,
  ]);

  return (
    <Menu style={{width: 256}} selectedKeys={selectedKeys} mode="inline">
      <Menu.Item
        key={'info'}
        onClick={() => actions.navigate('/profile', {loading: 800})}>
        個人資訊
      </Menu.Item>
      <Menu.Item
        key={'orders'}
        onClick={() => actions.navigate('/profile/orders', {loading: 800})}>
        我的訂單
      </Menu.Item>

      {isNormalUser && (
        <Menu.Item
          key={'reset-password'}
          onClick={() => showResetPasswordModal({admin: false})}>
          重設密碼
        </Menu.Item>
      )}

      <Menu.Item
        key={'logout'}
        onClick={() => {
          actions.logout();
          setTimeout(() => {
            actions.navigate('/', {loading: 800});
          }, 100);
        }}>
        登出
      </Menu.Item>
    </Menu>
  );
}

export default ProfileMenu;
export {ProfileTabs};
