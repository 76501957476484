import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as CartActions from '../../Actions/Cart';
import ProductVariants from '../ProductVariants';
import {BREAK_POINTS, CART_ITEMS_LIMIT} from '../../Domain/Constants';
import {Delete} from '@styled-icons/fluentui-system-filled/Delete';
import {navigate} from 'gatsby';
import {message} from 'antd';

function CartItem(props) {
  const {item, itemIdx, disabled, editable = false} = props;
  const [actions] = useOutlet('actions');
  const [cart] = useOutlet('cart');

  async function removeFromCart() {
    try {
      actions.setLoading(true);
      await CartActions.removeFromCart(itemIdx);
    } finally {
      actions.setLoading(false);
    }
  }

  const onEditCart = async (params) => {
    try {
      actions.setLoading(true);
      const _nextQty =
        cart.items.reduce((acc, cur, index) => {
          if (index !== itemIdx) {
            acc += cur.config.qty;
          }
          return acc;
        }, 0) + params.qty;
      if (_nextQty > CART_ITEMS_LIMIT) {
        const error = new Error('over_cart_items_limits');
        error.body = {
          error: 'over_cart_items_limits',
        };
        throw error;
      }
      await CartActions.editCart(itemIdx, {
        qty: params.qty,
        spec: params.spec,
        logisticType: item.config.logistic_type,
      });
    } catch (ex) {
      if (ex && ex.body) {
        if (ex.body.error === 'over_cart_items_limits') {
          message.warn('已超過單次購買數量限制');
        } else if (ex.body.error === 'qty_out_of_limit') {
          message.warn('超過購物車購買數量上限');
        } else if (ex.body.error === 'qty_exceed_limit') {
          message.warn('超過購物車購買數量上限');
        } else if (ex.body.error === 'out_of_stock') {
          message.warn(
            '您選擇的出貨日期庫存不足，請嘗試其他數量或是另選出貨日期',
          );
        } else {
          message.error('發生錯誤, 請稍後再重新嘗試');
        }
      } else {
        message.error('發生錯誤, 請稍後再重新嘗試');
      }
      throw ex;
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <ItemWrapper isFirst={itemIdx === 0}>
      <ItemCol>
        <div className="header">品名</div>

        <div className="product-context">
          <img
            src={item.product.images?.[0].expected_url || ''}
            alt="product thumbnail"
          />
          <div className="item-content product-name">
            <div
              style={{cursor: 'pointer'}}
              onClick={async () => {
                await navigate(`/product?id=${item.product.id}`);
              }}
              className="name">
              {item.product.name}
            </div>
            <div style={{flex: 1}} />
          </div>
        </div>
      </ItemCol>

      <ItemCol style={{flex: 1}}>
        <div className="header">規格</div>
        <div className="item-content product-variant">
          <ProductVariants
            disabled={true}
            product={item.product}
            config={item.config}
            specs={[item.spec]}
            onEditCart={editable ? onEditCart : null}
            rowStyle={{justifyContent: 'flex-start'}}
          />
        </div>
      </ItemCol>

      <ItemCol style={{flex: 1}}>
        <div className="header">單價</div>

        <div className="item-content product-price">
          <div className="field">
            <div className="name">單價</div>
            <div className="value">$ {item.product.price}</div>
          </div>
        </div>
      </ItemCol>

      <ItemCol style={{flex: 1}}>
        <div className="header">小計</div>

        <div className="item-content product-sum">
          <div className="field">
            <div className="name">小計</div>
            <div className="value">$ {item.amount}</div>
          </div>
        </div>
      </ItemCol>

      <ItemCol>
        <div className="header" />

        <div className="item-content">
          {!disabled && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Delete
                style={{cursor: 'pointer'}}
                onClick={removeFromCart}
                size={30}
                color={'#1E2F29'}
              />
            </div>
          )}
        </div>
      </ItemCol>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  width: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: 3fr 4fr 1fr 1fr 1fr;

  & .header {
    display: ${({isFirst}) => (isFirst ? 'block' : 'none')};
    padding: 8px 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    min-height: 40px;
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    & .header {
      display: none;
    }
  }
`;

const ItemCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > .item-content {
    display: flex;
    align-self: flex-start;
    padding: 30px 0 0 0;
  }
  & > .product-context {
    display: flex;
    padding: 30px 50px 0 0;
    & > img {
      width: 100px;
      height: 100px;
      object-fit: contain;
      background-color: #eee;
    }
    & > .item-content {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      font-size: 12px;
      & > .name {
        font-size: 14px;
      }
    }
  }

  & > .item-content.product-sum,
  .item-content.product-price {
    font-size: 20px;
    & > .field {
      & > .name {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    & > .item-content {
      padding: 3px 0;
      display: flex;
      flex-direction: column;
    }
    & > .item-content.product-variant {
      margin: 2px 0;
    }

    & > .item-content.product-sum,
    .item-content.product-price {
      font-size: 14px;
      & > .field {
        display: flex;
        & > .name {
          margin-right: 4px;
          display: block;
        }
      }
    }

    & > .product-context {
      display: flex;
      padding: 0px;
      & > img {
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.xxs}px) {
    & > .item-content.product-variant {
      margin: 2px auto;
    }
    & > .product-context {
      flex-direction: column;
      margin: 0 auto;

      & > img {
        margin: 0 auto;
      }

      & > .item-content {
        padding-left: 0px;
        text-align: center;
        margin-bottom: 5px;
      }
    }
  }
`;

export default CartItem;
