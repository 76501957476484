import React from 'react';
import styled from 'styled-components';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Spin,
} from 'antd';
import {useOutlet} from 'reconnect.js';
import moment from 'moment';

function ProductSpecsForm(props) {
  const {instance, updateProduct, values} = props;
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = React.useState(false);
  const [showCreateModal, setShowSpecModal] = React.useState(false);
  const [specs, setSpecs] = React.useState([]);
  const [selectedSpec, setSelectedSpec] = React.useState(null);

  React.useEffect(() => {
    const _fetchSpecs = async () => {
      if (!instance.specs || instance.specs.length === 0) {
        return;
      }
      try {
        setLoading(true);
        let resp = await actions.fetchDocuments(
          'spec',
          {
            $or: instance.specs.map((sid) => ({id: sid})),
          },
          ['created'],
          {},
        );
        setSpecs(resp.results);
      } catch (err) {
        message.error('無法取得規格資訊');
      } finally {
        setLoading(false);
      }
    };

    _fetchSpecs();
  }, [instance.specs, actions]);

  const _addSpec = React.useCallback(async () => {
    setSelectedSpec(null);
    setShowSpecModal(true);
  }, []);

  const _editSpec = React.useCallback(async (spec) => {
    setSelectedSpec(spec);
    setShowSpecModal(true);
  }, []);

  const _onCreateConfirm = React.useCallback(
    async (data) => {
      let _result = await actions.createDocument('spec', data);
      let _resultSpecs = [...specs.map((s) => s.id), _result.id];
      updateProduct({...values, specs: _resultSpecs});
      await actions.updateDocument(
        'product',
        {id: values.id},
        {...values, specs: _resultSpecs},
      );
      setSpecs([...specs, _result]);
    },
    [actions, specs, updateProduct, values],
  );

  const _onEditConfirm = React.useCallback(
    async (data) => {
      const _result = await actions.updateDocument(
        'spec',
        {id: data.id},
        {...data},
      );
      const _resultSpecs = specs.filter((s) => s.id !== data.id);
      setSpecs(
        [..._resultSpecs, _result].sort((a, b) => a.created - b.created),
      );
    },
    [actions, specs],
  );

  const _onDeleteSpec = React.useCallback(
    async (spec) => {
      try {
        const hide = message.loading('移除...');
        await actions.deleteDocument('spec', {id: spec.id});
        hide();
        message.success('規格已移除!');
        let _resultSpecs = specs.filter((s) => s.id !== spec.id);
        updateProduct({...values, specs: _resultSpecs.map((s) => s.id)});
        await actions.updateDocument(
          'product',
          {id: values.id},
          {...values, specs: _resultSpecs.map((s) => s.id)},
        );
        setSpecs(_resultSpecs);
      } catch (err) {
        message.error('無法刪除此規格！請聯絡工程端');
      }
    },
    [updateProduct, values, specs, actions],
  );

  const uiSpecItems = React.useMemo(() => {
    return specs.map((s) => ({
      ...s,
      _delivery_date: moment(s.delivery_date.$datetime).format('YYYY-MM-DD'),
      _publish_time: moment(s.publish_time.$datetime).format(
        'YYYY-MM-DD HH:mm',
      ),
      _end_publish_time: moment(s.end_publish_time.$datetime).format(
        'YYYY-MM-DD HH:mm',
      ),
    }));
  }, [specs]);

  return (
    <Wrapper>
      <div className="items">
        {loading ? (
          <Spin />
        ) : (
          uiSpecItems.map((spec, idx) => (
            <div
              className="spec"
              key={idx}
              style={{borderTop: idx !== 0 ? '1px solid #ccc' : 'none'}}>
              <div className="display">
                {spec.display} ({spec.stock})
              </div>
              <div className="text">出貨 {spec._delivery_date}</div>
              <div className="text">上架時間 {spec._publish_time}</div>
              <div className="text">下架時間 {spec._end_publish_time}</div>

              <div className="delete">
                <Button size="small" onClick={() => _onDeleteSpec(spec)}>
                  移除
                </Button>
              </div>
              <div className="edit">
                <Button size="small" onClick={() => _editSpec(spec)}>
                  編輯
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
      {uiSpecItems.length < 10 && (
        <Button size="small" onClick={_addSpec} style={{marginTop: 10}}>
          新增規格
        </Button>
      )}
      <SpecFormModal
        visible={showCreateModal}
        setVisible={setShowSpecModal}
        onCreate={_onCreateConfirm}
        onEdit={_onEditConfirm}
        selectedSpec={selectedSpec}
        setSelectedSpec={setSelectedSpec}
      />
    </Wrapper>
  );
}

function SpecFormModal(props) {
  const {
    visible,
    setVisible,
    onCreate,
    onEdit,
    setSelectedSpec,
    selectedSpec,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState({});
  const isEdit = !!selectedSpec;

  React.useEffect(() => {
    if (selectedSpec) {
      setValues((prev) => ({
        ...prev,
        ...selectedSpec,
        delivery_date: moment(selectedSpec.delivery_date.$datetime),
        publish_time: moment(selectedSpec.publish_time.$datetime),
        end_publish_time: moment(selectedSpec.end_publish_time.$datetime),
      }));
    } else {
      setValues({});
    }
  }, [selectedSpec]);

  const fields = [
    {key: 'display', type: 'string', title: '名稱', disabled: isEdit},
    {key: 'delivery_date', type: 'date', title: '運送日期', disabled: isEdit},
    {
      key: 'publish_time',
      type: 'datetime',
      title: '上架時間',
      disabled: isEdit,
    },
    {
      key: 'end_publish_time',
      type: 'datetime',
      title: '下架時間',
      disabled: isEdit,
    },
    {key: 'stock', type: 'number', title: '庫存', disabled: false},
  ];

  const _onConfirm = React.useCallback(
    async (values) => {
      setLoading(true);
      try {
        let _data = {
          ...values,
          delivery_date: {
            $datetime: values.delivery_date
              .set({hour: 0, minute: 0, second: 0})
              .toDate()
              .getTime(),
          },
          publish_time: {
            $datetime: values.publish_time.toDate().getTime(),
          },
          end_publish_time: {
            $datetime: values.end_publish_time.toDate().getTime(),
          },
        };
        if (isEdit) {
          await onEdit({id: selectedSpec.id, ..._data});
          message.success('編輯完成！');
        } else {
          await onCreate(_data);
          message.success('新增完成！');
        }
        setVisible(false);
        setSelectedSpec(null);
      } catch (err) {
        if (isEdit) {
          message.error('無法編輯規格！');
        } else {
          message.error('無法新增規格！');
        }
      } finally {
        setLoading(false);
      }
    },
    [isEdit, onCreate, onEdit, selectedSpec, setSelectedSpec, setVisible],
  );

  return (
    <Modal
      destroyOnClose
      title={isEdit ? '編輯規格' : '新增規格'}
      footer={null}
      bodyStyle={{padding: 20}}
      width={500}
      visible={visible}
      onOk={_onConfirm}
      onCancel={() => {
        setVisible(false);
      }}>
      <Form
        onFinish={_onConfirm}
        onFinishFailed={() => 0}
        initialValues={values}
        onValuesChange={(_theFieldBeChanged, _allFields) => {
          setValues((prev) => ({...prev, ..._allFields}));
        }}>
        {fields.map((f, idx) => {
          return (
            <Form.Item
              key={idx}
              label={f.title}
              name={f.key}
              rules={[
                {
                  required: true,
                  message: '必填',
                },
              ]}>
              {(() => {
                if (f.type === 'date') {
                  return <DatePicker disabled={f.disabled} />;
                } else if (f.type === 'datetime') {
                  return (
                    <DatePicker
                      disabled={f.disabled}
                      showTime
                      format={'YYYY-MM-DD HH:mm'}
                    />
                  );
                } else if (f.type === 'number') {
                  return <InputNumber disabled={f.disabled} />;
                } else {
                  return <Input disabled={f.disabled} />;
                }
              })()}
            </Form.Item>
          );
        })}

        <Form.Item style={{textAlign: 'right'}}>
          <Button type="primary" htmlType="submit" loading={loading}>
            確認
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

const Wrapper = styled.div`
  & .items {
    & > .spec {
      position: relative;
      padding: 10px 0px;
      margin-bottom: 5px;

      & > .delete,
      .edit {
        position: absolute;
        right: 10px;
      }
      & > .delete {
        top: 10px;
      }
      & > .edit {
        top: 40px;
      }
    }
  }
`;
const InputField = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  & > label {
    margin-right: 10px;
  }
`;

export default ProductSpecsForm;
