import React from 'react';

export default function useBlockDocumentScroll(shouldBlockScroll) {
  React.useEffect(() => {
    if (shouldBlockScroll) {
      document.body.style.overflow = 'hidden';
      document.body.style['touch-action'] = 'none';
      document.body.style['-ms-touch-action'] = 'none';
    }

    return () => {
      document.body.style.overflow = 'auto';
      document.body.style['touch-action'] = 'unset';
      document.body.style['-ms-touch-action'] = 'unset';
    };
  }, [shouldBlockScroll]);
  return null;
}
