import React from 'react';
import styled from 'styled-components';
import {CSVLink} from 'react-csv';
import {Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {
  LOGISTIC_STATUS,
  LOGISTIC_STATUS_DISPLAY,
  LOGISTIC_TYPE_DISPLAY,
  ORDER_STATUS_DISPLAY,
  PAYMENT_STATUS_DISPLAY,
} from '../Domain/Constants';
import moment from 'moment';

const _headers = [
  {key: 'id', label: '訂單編號'},
  {key: 'buyer_name', label: '購買人姓名'},
  {key: 'buyer_phone', label: '購買人手機'},
  {key: 'buyer_email', label: '購買人電子信箱'},
  {key: 'total', label: '金額 (NT)'},
  {key: 'payment_status', label: '付款狀態'},
  {key: 'logistic_status', label: '物流狀態'},
  {key: 'status', label: '訂單狀態'},
  {key: 'bank_account', label: '繳費虛擬帳號'},
  {key: 'logistic_type', label: '運送方式'},
  {key: 'receiver_name', label: '收件人姓名'},
  {key: 'receiver_phone', label: '收件人電話'},
  {key: 'receiver_address', label: '收件人地址'},
  {key: 'row_item', label: '商品名稱'},
  {key: 'qty', label: '數量'},
  {key: 'order_note', label: '客戶備註'},
  {key: 'staff_note', label: '員工備註'},
  {key: 'created', label: '成立時間'},
  {key: 'delivery_date', label: '出貨時間'},
];

const _remapOrderDataToDisplayString = (order) => {
  const _wrapStr = (str) => (str ? `=""${str}""` : '');

  return {
    ...order,
    id: _wrapStr(order.id),
    buyer_phone: _wrapStr(order.buyer_phone),
    logistic_type: LOGISTIC_TYPE_DISPLAY[order.logistic_type],
    receiver_address: `${order.receiver_zip || ''}${order.receiver_city || ''}${
      order.receiver_district || ''
    }${order.receiver_address || ''}`,
    row_item: order.row_item.name,
    qty: order.row_item.config?.qty,
    bank_account: !!order.bank_account
      ? _wrapStr(`(${order.bank_code})${order.bank_account}`)
      : '',
    payment_status: PAYMENT_STATUS_DISPLAY[order.payment_status],
    logistic_status: !!order.logistic_status
      ? LOGISTIC_STATUS_DISPLAY[
          order.logistic_status || LOGISTIC_STATUS.pending
        ]
      : '',
    status: ORDER_STATUS_DISPLAY[order.status],
    created: !!order.created
      ? moment(order.created).format('YYYY-MM-DD HH:mm')
      : '',
    delivery_date: !!order.delivery_date
      ? moment(order.delivery_date.$datetime).format('YYYY-MM-DD')
      : '',
  };
};

function CsvOrderExportButton(props) {
  const {style = {}, queryState = {}} = props;

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [cvsData, setCvsData] = React.useState({
    filename: '',
  });
  const csvInstance = React.useRef();

  React.useEffect(() => {
    if (
      Array.isArray(data) &&
      data.length > 0 &&
      csvInstance &&
      csvInstance.current.link
    ) {
      let timer = setTimeout(() => {
        csvInstance.current.link.click();
        setLoading(false);
        setData([]);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [data, csvInstance]);

  const _multiPageDownload = React.useCallback(
    async (config) => {
      const PAGE_SIZE = 50;
      let total = 0;
      let _pageIndexs = [];
      let results = [];
      let _config = {
        ...config,
      };
      delete _config.paging;

      try {
        let _firstTry = await JStorage.fetchDocuments(
          'order',
          _config.query,
          _config.sort,
          {
            offset: 0,
            limit: 5,
          },
        );

        total = _firstTry.total;
        _pageIndexs = new Array(Math.ceil(total / PAGE_SIZE))
          .fill('')
          .map((i, idx) => idx);
      } catch (err) {
        alert('fail to get initial page data!');
        return [];
      }

      for (let idx of _pageIndexs) {
        try {
          let _pageData = await JStorage.fetchDocuments(
            'order',
            _config.query,
            _config.sort,
            {
              offset: idx * PAGE_SIZE,
              limit: PAGE_SIZE,
            },
          );

          results.push(..._pageData.results);
        } catch (err) {
          //
          alert('fail to get page data!');
          console.log(idx, err);
          return [];
        }
      }

      return results;
    },
    [csvInstance, queryState],
  );

  const _onDownload = React.useCallback(async () => {
    console.log(queryState);

    setLoading(true);
    // related to order.json searchFields [id, buyer_phone]
    function getKeywordQuery(keyword) {
      const searchArr = [];
      for (const field of ['id', 'buyer_phone']) {
        searchArr.push({[field]: {$regex: `${keyword}`}});
      }

      return keyword
        ? {
            $or: searchArr,
          }
        : {};
    }

    let _activeFilterConfig = {
      query: {
        ...getKeywordQuery(queryState.keyword, queryState),
        ...queryState.filter,
        ...queryState.extraQueries,
      },
      sort: [queryState.sort],
    };

    let _data = await _multiPageDownload(_activeFilterConfig);

    const dataTransformToPerItem = _data.reduce((acc, cur) => {
      acc = [
        ...acc,
        ...cur.items.map((item, index) => {
          if (index === 0) {
            return {
              ...cur,
              row_item: item,
            };
          } else {
            return {
              id: '',
              buyer_phone: '',
              logistic_type: '',
              receiver_address: '',
              bank_account: '',
              bank_code: '',
              payment_status: '',
              logistic_status: '',
              status: '',
              created: '',
              delivery_date: '',
              row_item: item,
            };
          }
        }),
      ];
      return acc;
    }, []);

    let _remapToDisplayData = dataTransformToPerItem.map(
      _remapOrderDataToDisplayString,
    );
    setData(_remapToDisplayData);
    let _filename = `訂單.csv`;
    setCvsData({
      filename: _filename,
    });
  }, [csvInstance, queryState]);

  return (
    <Wrapper style={style}>
      {Array.isArray(data) && data.length > 0 ? (
        <CSVLink
          ref={csvInstance}
          filename={cvsData.filename || '訂單報表'}
          headers={_headers}
          data={data}>
          <Button loading={loading} onClick={() => 0}>
            下載
          </Button>
        </CSVLink>
      ) : (
        <Button loading={loading} onClick={_onDownload}>
          下載
        </Button>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default CsvOrderExportButton;
