import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Input} from 'antd';
import {
  BREAK_POINTS,
  LOGISTIC_STATUS_DISPLAY,
  LOGISTIC_STATUS,
  LOGISTIC_TYPE_DISPLAY,
  PAYMENT_STATUS_DISPLAY,
} from '../Domain/Constants';

function OrderInfo(props) {
  const {order, isAdmin = false, footerEle = null} = props;

  const ORDER_DISPLAY_GROUPS = React.useMemo(() => {
    if (!order) {
      return {
        group1: [],
        group2: [],
        group3: [],
      };
    }
    const _group1 = [
      {title: '購買人姓名', value: order.buyer_name},
      {title: '購買人電子信箱', value: order.buyer_email},
      {title: '購買人電話', value: order.buyer_phone},
      // {title: '客戶備註', value: order.order_note},
      {title: '付款方式', value: 'ATM 虛擬帳號'},
    ];

    const _group2 = [
      {title: '收件人姓名', value: order.receiver_name},
      {title: '收件人電話', value: order.receiver_phone},
      {
        title: '收件地址',
        value: `${order.receiver_zip}${order.receiver_city}${order.receiver_district}${order.receiver_address}`,
      },
      {title: '運送方式', value: LOGISTIC_TYPE_DISPLAY[order.logistic_type]},
      /*
      {
        title: '出貨時間',
        value: moment(
          order.delivery_date.$date || order.delivery_date.$datetime,
        ).format('YYYY / MM / DD hh:mm'),
      },
      */
    ];
    const _group3 = [
      {
        title: '繳費虛擬帳號',
        value: `(${order.bank_code || '-'})${order.bank_account || '-'}`,
      },
      {title: '繳費期限', value: order.pay_deadline},
      {title: '付款狀態', value: PAYMENT_STATUS_DISPLAY[order.payment_status]},
      {
        title: '物流狀態',
        value:
          (order.logistic_status === LOGISTIC_STATUS.exception
            ? order.logistic_detail?.RtnMsg
            : LOGISTIC_STATUS_DISPLAY[order.logistic_status]) || '-',
      },
      {
        title: '物流單號',
        value: order.logistic_detail?.BookingNote || '尚未產生物流單號',
      },
    ];

    return {
      group1: _group1,
      group2: _group2,
      group3: _group3,
    };
  }, [order]);

  return (
    <Wrapper>
      <div className="content">
        <div className="form" style={{marginTop: 50, marginBottom: 30}}>
          <h2>訂單狀態</h2>
          <div className="form-content">
            <InputField style={{marginBottom: 15}}>
              <label>購買時間</label>
              <Input
                disabled
                value={moment(order.created).format('YYYY / MM / DD HH:mm')}
              />
            </InputField>
            <InputField style={{marginBottom: 15}}>
              <label>訂單編號</label>
              <Input
                disabled
                value={order.order_number}
                style={{color: '#846E4F', fontWeight: 'bold'}}
              />
            </InputField>
          </div>
        </div>

        <div className="form" style={{marginTop: 50, marginBottom: 30}}>
          <h2>訂單資訊</h2>
          <div
            className="form-content"
            style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            <div className="col" style={{marginLeft: 0}}>
              {ORDER_DISPLAY_GROUPS.group1.map((item, idx, arr) => (
                <InputField
                  key={idx}
                  style={{marginBottom: idx === arr.length - 1 ? 0 : 15}}>
                  <label>{item.title}</label>
                  <Input disabled value={item.value} />
                </InputField>
              ))}
            </div>

            <div className="col">
              {ORDER_DISPLAY_GROUPS.group2.map((item, idx, arr) => (
                <InputField
                  key={idx}
                  style={{marginBottom: idx === arr.length - 1 ? 0 : 15}}>
                  <label>{item.title}</label>
                  <Input disabled value={item.value} />
                </InputField>
              ))}
            </div>

            <div className="col">
              {ORDER_DISPLAY_GROUPS.group3.map((item, idx, arr) => (
                <InputField
                  key={idx}
                  style={{marginBottom: idx === arr.length - 1 ? 0 : 15}}>
                  <label>{item.title}</label>
                  <Input disabled value={item.value} />
                  {item.title === '物流單號' && (
                    <div
                      className="link"
                      onClick={() => {
                        window.open(
                          'https://www.t-cat.com.tw/Inquire/Trace.aspx',
                          '_blank',
                        );
                      }}>
                      黑貓貨態查詢
                    </div>
                  )}
                </InputField>
              ))}
            </div>
          </div>
          <div style={{margin: '15px 0'}}>
            <InputField>
              <label>客戶備註</label>
              <Input.TextArea disabled value={order.order_note} />
            </InputField>
          </div>
          {isAdmin && order.logistic_status === LOGISTIC_STATUS.error && (
            <div className="error">
              * {LOGISTIC_STATUS_DISPLAY[order.logistic_status]} |{' '}
              {order.logistic_detail?.error || '-'}
            </div>
          )}
          <div className="footer">{footerEle}</div>
        </div>
      </div>
    </Wrapper>
  );
}

const InputField = styled.div`
  & > label {
    margin-bottom: 3px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 25px;
  }

  & input[disabled] {
    background-color: transparent;
    color: #000;
  }
`;

const Wrapper = styled.div`
  & > .header {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .circle {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      background-color: #846e4f;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      & img {
        width: 96px;
        height: 96px;
      }
    }

    & > h2 {
      margin-bottom: 20px;
      font-size: 20px;
      letter-spacing: 0;
      text-align: center;
      line-height: 30px;
    }

    & > p {
      margin-bottom: 34px;
      font-size: 14px;
      letter-spacing: 0;
      text-align: center;
      line-height: 25px;
    }
  }

  & .form {
    padding: 30px 50px;
    border: 1px solid #dddddd;
    border-radius: 10px;

    & > h2 {
      font-size: 20px;
      letter-spacing: 0;
      padding-bottom: 10px;
    }

    & > .form-content {
      border-top: 1px solid #846e4f;
      border-bottom: 1px solid #846e4f;

      padding-bottom: 27px;
      padding-top: 15px;

      & > .col {
        margin-left: 35px;
        flex-basis: calc((100% - 70px) / 3);
        @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
          flex-basis: 100%;
          margin-left: 0px;
          margin-bottom: 15px;
        }
        & .link {
          color: #846e4f;
          text-align: right;
          :hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    & .error {
      color: red;
      margin-top: 5px;
    }

    & > .footer {
      display: flex;
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.xxs}px) {
    & > .content > .form {
      padding: 10px !important;
    }
  }
`;

export default OrderInfo;
