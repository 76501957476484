import React from 'react';
import FixedRatioImage from '../src/Components/FixedRatioImage';
import ProductListTab from './ProductListTab';
import {CATEGORY} from '../src/Domain/Constants';

function renderCustomSection(props) {
  const {route, sectionId, params} = props;
  if (route === '/products') {
    let _findCat = CATEGORY[params.cat] || CATEGORY.all;
    if (sectionId === 'E') {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: 50,
            marginTop: 35,
          }}>
          <ProductListTab {...props} />
        </div>
      );
    }
    return null;
  }

  if (route === '/product') {
    return null;
  }

  if (route.indexOf('/checkout') === 0) {
    return null;
    /*
    const subRoute = route.split('/')[2];
    let current = 0;

    if (subRoute === 'info') {
      current = 1;
    } else if (subRoute === 'review') {
      current = 2;
    }

    if (sectionId === 'B') {
      return (
        <Steps current={current} style={{marginBottom: 20}}>
          <Steps.Step title="購物車" />
          <Steps.Step title="寄送資訊" />
          <Steps.Step title="付款" />
        </Steps>
      );
    }
    */
  }

  return (
    <section
      style={{padding: 40, backgroundColor: '#ccc', border: '3px solid red'}}>
      <h2 style={{textAlign: 'center'}}>{`CustomSection ${sectionId}`}</h2>
    </section>
  );
}

function renderCustomComponent(props) {
  return null;
}

export {renderCustomSection, renderCustomComponent};
