import './AppOutlets';
import './SdkInit';
import {getOutlet} from 'reconnect.js';
import * as AppActions from './AppActions';
import * as CartActions from './Actions/Cart';
import * as UserActions from 'rev.sdk.js/Actions/User';
import * as JStorageActions from 'rev.sdk.js/Actions/JStorage';

const ActionOutlet = getOutlet('actions');

ActionOutlet.update({
  ...UserActions,
  ...JStorageActions,
  ...CartActions,
  ...AppActions,
});

console.log('App initialized');
