import React from 'react';
import {navigate} from 'gatsby';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import {CATEGORY} from '../src/Domain/Constants';

function ProductListTab(props) {
  const {params, themeColor = '#1e2f29'} = props;
  const cat = params.cat;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.innerWidth < 800;
  const commonProps = {mobile, themeColor};
  return (
    <Wrapper {...commonProps}>
      {/*<Btn*/}
      {/*  selected={!cat}*/}
      {/*  onClick={() => navigate('/products')}*/}
      {/*  {...commonProps}>*/}
      {/*  {CATEGORY.all.display}*/}
      {/*</Btn>*/}

      {/*FIXME: should change back*/}
      {Object.keys(CATEGORY).map((k, idx) => (
        <Btn
          key={idx}
          selected={cat === CATEGORY[k].key}
          onClick={() => navigate(`/products?cat=${CATEGORY[k].key}`)}
          {...commonProps}>
          {CATEGORY[k].display}
        </Btn>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  border: 1px solid
    ${(props) => (props.mobile ? 'transparent' : props.themeColor)};
`;

const Btn = styled.div`
  --background-color: #fff;
  --hover-background-color: #e8e8e8;
  --text-color: #1e2f29;

  ${(props) =>
    props.selected
      ? `
      --background-color: #1e2f29;
      --hover-background-color: #41504b;
      --text-color: #fff;
      `
      : ''}

  cursor: pointer;
  padding: 8px 12px;
  width: 180px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.selected ? 'white' : props.themeColor)};
  background-color: ${(props) =>
    props.selected ? props.themeColor : 'transparent'};

  &:hover {
    background: var(--hover-background-color);
  }

  ${(props) =>
    props.mobile
      ? `
    border-top: 1px solid ${props.themeColor};
    border-right: 1px solid ${props.themeColor};
    border-left: 1px solid ${props.themeColor};
  `
      : `
    border-right: 1px solid ${props.themeColor};
  `}

  &:last-child {
    border-right: 1px solid
      ${(props) => (props.mobile ? props.themeColor : 'transparent')};
    border-bottom: 1px solid
      ${(props) => (props.mobile ? props.themeColor : 'transparent')};
  }
`;
export default ProductListTab;
