import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import useBreakpoint from '../Hooks/useBreakPoint';
import {Menu} from '@styled-icons/ionicons-sharp/Menu';
import {LogoFacebook} from '@styled-icons/ionicons-solid/LogoFacebook';
import {LogoInstagram} from '@styled-icons/ionicons-solid/LogoInstagram';
import {Close} from '@styled-icons/ionicons-sharp/Close';
import imgLogoVertical from '../images/logo-v.png';
import imgLogoHorizontal from '../images/logo-h.png';
import {BREAK_POINTS, LINKS} from '../Domain/Constants';
import useBlockDocumentScroll from '../Hooks/useBlockDocumentScroll';
import {ShoppingCart} from '@styled-icons/material-outlined/ShoppingCart';
import {Badge} from 'antd';
import * as AppActions from '../AppActions/index';

const routes = [
  {
    key: 'home',
    label: 'Home',
    link: '/',
  },
  {
    key: 'online-menu',
    label: 'Online MENU',
    link: '/online-menu',
  },
  {
    key: 'about',
    label: 'About',
    link: '/about',
  },
  // {/*FIXME: should change back*/}
  {
    key: 'shop',
    label: 'Shop',
    link: '/products?cat=logistics',
  },
  {
    key: 'contact',
    label: 'Contact',
    link: '/contact',
  },
  {
    key: 'profile',
    label: 'Profile',
    link: '/profile',
  },
  {
    key: 'cart',
    label: 'Cart',
    link: '/checkout',
  },
];

function SiteNavBar(props) {
  const [cart] = useOutlet('cart');
  const [menuOpen, setMenuOpen] = useOutlet('menu-open');
  const showLoginModal = useOutletSetter('login-modal');
  const [user] = useOutlet('user');
  const {passBreakpoint} = useBreakpoint(100);
  useBlockDocumentScroll(menuOpen);

  const _onMenuClick = React.useCallback(() => {
    setMenuOpen(true);
  }, [setMenuOpen]);

  const _onItemClick = React.useCallback(
    (route) => () => {
      AppActions.navigate(route.link, {loading: 200});
      setMenuOpen(false);
    },
    [setMenuOpen],
  );

  const _onGoMenuClick = React.useCallback(() => {
    // {/*FIXME: should change back*/}
    AppActions.navigate('/products?cat=logistics', {loading: true});
  }, []);

  return (
    <NavBar hasBorder={passBreakpoint}>
      <img
        onClick={async () => {
          await navigate('/');
        }}
        src={imgLogoHorizontal}
        style={{
          width: 170,
          height: 50,
          objectFit: 'contain',
          cursor: 'pointer',
        }}
        alt="dot-dot-logo"
      />
      <div style={{flex: 1}} />
      <div className="items">
        <div className="item" onClick={_onGoMenuClick}>
          Shop Now
        </div>
        {user ? (
          <div
            className="item"
            onClick={() => AppActions.navigate('/profile', {loading: 100})}>
            Profile
          </div>
        ) : (
          <div className="item" onClick={() => showLoginModal(true)}>
            Login
          </div>
        )}
      </div>

      <div className="cart">
        <Badge color={'#846e4f'} count={cart.items.length} showZero>
          <ShoppingCart
            onClick={async () => {
              await navigate('/checkout');
            }}
            size={24}
            color={'#1E2F29'}
          />
        </Badge>
      </div>
      <div className="menu" onClick={_onMenuClick}>
        <Menu size={24} color={'#1E2F29'} />
      </div>

      <FullPageMenu className={menuOpen ? 'open' : 'close'}>
        <div className="close-btn" onClick={() => setMenuOpen(false)}>
          <Close size={24} />
        </div>
        <img alt="logo" src={imgLogoVertical} width={156} />
        <div className="items">
          {routes.map((r, idx) => (
            <div className="item" key={idx} onClick={_onItemClick(r)}>
              {r.label}
            </div>
          ))}
        </div>
        <div className="socials">
          <a
            className="social"
            style={{marginRight: 20}}
            href={LINKS.facebook}
            target={'_blank'}
            rel="noreferrer">
            <LogoFacebook color={'#000'} size={24} />
          </a>
          <a
            className="social"
            href={LINKS.instagram}
            target={'_blank'}
            rel="noreferrer">
            <LogoInstagram color={'#000'} size={24} />
          </a>
        </div>
      </FullPageMenu>
    </NavBar>
  );
}

const FullPageMenu = styled.div`
  --animation-duration: 0.1s;
  --animation-delay-duration: 0.2s;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.open {
    width: 100vw;
    height: 100vh;
    opacity: 1;
    visibility: visible;
    transition: opacity var(--animation-duration) ease-in, width 0s ease-in 0s,
      height 0s ease-in 0s;
  }

  &.close {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--animation-duration) ease-in,
      width 0s ease-in var(--animation-delay-duration),
      height 0s ease-in var(--animation-delay-duration);
  }

  & > .close-btn {
    position: fixed;
    right: 100px;
    top: 100px;
    padding: 10px;
    cursor: pointer;
  }

  & .items {
    padding: 10px 0px;
    border-top: 1px solid #846e4f;
    border-bottom: 1px solid #846e4f;

    & .item {
      font-size: 20px;
      color: #000000;
      letter-spacing: 0;
      padding: 25px 10px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }

    & .item:hover {
      color: #ab9473;
    }
  }

  & > .socials {
    display: flex;
    margin-top: 20px;

    & > .social:hover {
      opacity: 0.6;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
    justify-content: flex-start;
    padding-top: 20px;
    overflow: auto;

    & > .close-btn {
      right: 10px;
      top: 10px;
    }
  }
`;

const NavBar = styled.div`
  position: fixed;
  background-color: #fff;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: var(--topNavBarHeight);
  padding: 16px 40px;
  border-bottom: 1px solid #846e4f;
  display: flex;
  align-items: center;
  z-index: 11;
  box-shadow: ${(props) =>
    props.hasBorder ? '0px 3px 20px 0px rgba(132,110,79,0.6)' : 'none'};
  transition: 200ms;

  & > .items {
    display: flex;
    & .item {
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      padding: 25px 10px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
    }
    & .item:hover {
      color: #ab9473;
    }
  }

  & .menu,
  .cart {
    padding: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
    & > .items {
      display: none;
    }
  }
`;

export default SiteNavBar;
