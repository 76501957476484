import {getOutlet} from 'reconnect.js';
import Config from '../../data.json';
import {req} from '../Utils/ApiUtil';
import {message} from 'antd';

const UserOutlet = getOutlet('user');
const CartOutlet = getOutlet('cart');

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function _cartExceptionHandler(func) {
  return async (...params) => {
    try {
      return await func(...params);
    } catch (err) {
      if (
        ['invalid_cart_items', 'spec_not_found', 'product_not_found'].indexOf(
          err?.body?.error,
        ) !== -1
      ) {
        message.warn('購物車有失效或庫存不足商品，系統已自動移除');

        let nextCart = await req(
          `${Config.apiHost}/cart?token=${UserOutlet.getValue().token}`,
        );
        CartOutlet.update(nextCart);
        return nextCart;
      } else {
        throw err;
      }
    }
  };
}

async function _fetchCart() {
  const nextCart = await req(
    `${Config.apiHost}/cart?token=${UserOutlet.getValue().token}`,
  );
  CartOutlet.update(nextCart);
  return nextCart;
}

//FIXME: should calc price api integration
async function calcPrice(productId, {qty, variants}, product) {
  /*
  return req(
    `${Config.apiHost}/cart/item/calc?token=${UserOutlet.getValue().token}`,
    {
      method: 'post',
      data: {
        product: productId,
        config: {
          qty,
          variants,
        },
      },
    },
  );
  */
  await delay(300);
  return {amount: qty * product.price};
}

async function _addToCart(productId, {qty, spec, variants, logisticType}) {
  const nextCart = await req(
    `${Config.apiHost}/cart/item/add?token=${UserOutlet.getValue().token}`,
    {
      method: 'post',
      data: {
        product: productId,
        config: {
          qty,
          variants,
          spec,
          logistic_type: logisticType,
        },
      },
    },
  );
  CartOutlet.update(nextCart);
  return nextCart;
}

async function _removeFromCart(index) {
  const nextCart = await req(
    `${Config.apiHost}/cart/item/delete?token=${UserOutlet.getValue().token}`,
    {
      method: 'post',
      data: {index},
    },
  );
  CartOutlet.update(nextCart);
  return nextCart;
  // await delay(600);
  // const nextCart = {...CartOutlet.getValue()};
  // nextCart.items.splice(index, 1);
  // CartOutlet.update({
  //   ...nextCart,
  //   total: nextCart.items.reduce((acc, item) => acc + item.amount, 0),
  // });
}

async function editConfig(nextConfig) {
  const currConfig = CartOutlet.getValue();
  const nextCart = await req(
    `${Config.apiHost}/cart/item/edit_config?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'post',
      data: {
        buyer_name: currConfig.buyer_name,
        buyer_phone: currConfig.buyer_phone,
        buyer_email: currConfig.buyer_email,
        buyer_zip: currConfig.buyer_zip,
        buyer_city: currConfig.buyer_city,
        buyer_district: currConfig.buyer_district,
        buyer_address: currConfig.buyer_address,
        buyer_tel: currConfig.buyer_tel,
        buyer_tel_ext: currConfig.buyer_tel_text,
        receiver_name: currConfig.receiver_name,
        receiver_phone: currConfig.receiver_phone,
        receiver_email: currConfig.receiver_email,
        receiver_zip: currConfig.receiver_zip,
        receiver_city: currConfig.receiver_city,
        receiver_district: currConfig.receiver_district,
        receiver_address: currConfig.receiver_address,
        receiver_tel: currConfig.receiver_tel,
        receiver_tel_ext: currConfig.receiver_tel_ext,
        order_note: currConfig.order_note,
        ...nextConfig,
      },
    },
  );
  CartOutlet.update(nextCart);
  return nextCart;
}

async function _editCart(cartItemIdx, {qty, spec, logisticType}) {
  const currConfig = CartOutlet.getValue();
  const nextCart = await req(
    `${Config.apiHost}/cart/item/edit?token=${UserOutlet.getValue().token}`,
    {
      method: 'post',
      data: {
        index: cartItemIdx,
        config: {
          qty,
          spec,
          logistic_type: logisticType,
        },
      },
    },
  );
  CartOutlet.update(nextCart);
  return nextCart;
}

async function getCheckoutUrl() {
  const checkoutUrl = `${Config.apiHost}/checkout/request?token=${
    UserOutlet.getValue().token
  }`;
  return checkoutUrl;
}

async function getAnonymousOrder(id, code) {
  const order = await req(
    `${Config.apiHost}/order?order_id=${id}&validation_code=${code}`,
    {
      method: 'get',
    },
  );
  return order;
}

async function getOrder(id) {
  const order = await req(
    `${Config.jstoreHost}/document/order/find-one?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'post',
      data: {
        query: {
          id: id,
        },
      },
    },
  );
  return order;
}

async function clearCart(itemIdx) {
  await delay(600);
  const cartValue = CartOutlet.getValue();
  const nextCartValue = {
    ...cartValue,
    items: [],
  };
  CartOutlet.update(nextCartValue);
}

const fetchCart = _cartExceptionHandler(_fetchCart);
const addToCart = _cartExceptionHandler(_addToCart);
const removeFromCart = _cartExceptionHandler(_removeFromCart);
const editCart = _cartExceptionHandler(_editCart);

export {
  fetchCart,
  calcPrice,
  addToCart,
  removeFromCart,
  editConfig,
  editCart,
  getCheckoutUrl,
  getOrder,
};
