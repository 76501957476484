import React from 'react';
import styled from 'styled-components';
import {Button, message, Table} from 'antd';
import useModal from '../Hooks/useModal';
import {useOutlet} from 'reconnect.js';
import OrderSpec from '../../content/admin/order.json';
import {
  LOGISTIC_STATUS_DISPLAY,
  ORDER_STATUS_DISPLAY,
  PAYMENT_STATUS_DISPLAY,
} from '../Domain/Constants';
import moment from 'moment';

function _genFakeOrders(orders, scale = 25) {
  let results = [];
  for (let i = 0; i < scale; i++) {
    results = results.concat(orders);
  }
  console.log('_genFakeOrders', results);
  return results;
}

function convertOrderData(order) {
  return {
    ...order,
    status: ORDER_STATUS_DISPLAY[order.status] || '-',
    payment_status: PAYMENT_STATUS_DISPLAY[order.payment_status] || '-',
    logistict_status: order.logistic_detail?.RtnMsg || '-',
    created: moment(order.created).format('YYYY-MM-DD'),
  };
}

function AdminShowUserOrderButton(props) {
  const {user} = props;
  const {modalElem, modalRef} = useModal({
    width: 'calc(100vw - 200px)',
    bodyStyle: {maxHeight: 'calc(100vh - 200px)'},
  });
  const [orders, setOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [actions] = useOutlet('actions');

  const onButtonClick = async function () {
    setLoading(true);
    try {
      let {results: _orders} = await actions.fetchDocuments(
        'order',
        {
          owner: user.owner,
        },
        ['created'],
        {},
      );

      _orders = _orders.map(convertOrderData);
      setOrders(_orders);
      modalRef.current.set({
        elem: (
          <UserOrderWrapper
            style={{paddingTop: '50px', backgroundColor: '#ebebeb'}}>
            <p>
              會員：{user.owner} {user.name} {user.phone}{' '}
            </p>
            <div className="orders">
              <Table
                dataSource={_orders}
                rowKey="id"
                pagination={{position: ['topRight']}}
                columns={[
                  ...OrderSpec.columns,
                  {
                    title: '',
                    key: 'operation',
                    width: 100,
                    render: (record) => (
                      <Button
                        onClick={() =>
                          actions.navigate(
                            `/admin/orders?action=detail&id=${record.id}`,
                          )
                        }>
                        詳細
                      </Button>
                    ),
                  },
                ]}
              />
            </div>
          </UserOrderWrapper>
        ),
      });
      modalRef.current.open();
    } catch (err) {
      message.error('無法取得訂單資料');
    }
    setLoading(false);
  };

  return (
    <>
      <Button loading={loading} onClick={onButtonClick} htmlType="button">
        訂單
      </Button>
      {modalElem}
    </>
  );
}

const UserOrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  & > p {
    margin-bottom: 5px;
    font-size: 16px;
  }

  & > .orders {
    margin-top: 20px;
  }
`;

export default AdminShowUserOrderButton;
