import React from 'react';
import styled from 'styled-components';
import {Button, Result, Space, Switch} from 'antd';
import {useOutletSetter} from 'reconnect.js';

function LoginRequiredUi(options) {
  const showLoginModal = useOutletSetter('login-modal');
  return (
    <Result
      title={`登入`}
      subTitle="此頁面需要登入，方能瀏覽"
      style={{marginTop: 'var(--topNavBarHeight)', paddingTop: 30}}
      extra={
        <Space direction="vertical">
          <Button onClick={() => showLoginModal(true)}>登入</Button>
        </Space>
      }
    />
  );
}

export default LoginRequiredUi;
