import React from 'react';
import styled from 'styled-components';
import {CSVLink} from 'react-csv';
import {Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import moment from 'moment';

const _headers = [
  {key: 'owner', label: '識別 ( owner )'},
  {key: 'name', label: '名稱'},
  {key: 'email', label: '信箱'},
  {key: 'phone', label: '電話'},
  {key: 'address', label: '地址'},
  {key: 'tel', label: '市話'},
  {key: 'provider', label: '登入方式'},
  {key: 'membership', label: '等級'},
  {key: 'created', label: '建立時間'},
  {key: 'updated', label: '更新時間'},
];

const _remapOrderDataToDisplayString = (user) => {
  const _wrapStr = (str) => (str ? `=""${str}""` : '');
  return {
    ...user,
    owner: _wrapStr(user.owner),
    name: _wrapStr(user.name),
    email: _wrapStr(user.email),
    phone: _wrapStr(user.phone),
    address: _wrapStr(
      `${user.zip || ''} ${user.city || ''}${user.district || ''}${
        user.address || ''
      }`,
    ),
    tel: _wrapStr(`${user.tel || ''}${user.tel_ext || ''}`),
    provider: _wrapStr(user.provider),
    membership: _wrapStr(user.membership),
    created: moment(user.created).format('YYYY-MM-DD HH:mm'),
    updated: moment(user.updated).format('YYYY-MM-DD HH:mm'),
  };
};

function UserExportCsvButton(props) {
  const {style = {}, queryState = {}} = props;

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const csvInstance = React.useRef();
  const [cvsData, setCvsData] = React.useState({
    filename: '',
  });

  React.useEffect(() => {
    if (
      Array.isArray(data) &&
      data.length > 0 &&
      csvInstance &&
      csvInstance.current.link
    ) {
      let timer = setTimeout(() => {
        csvInstance.current.link.click();
        setLoading(false);
        setData([]);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [data, csvInstance]);

  const _multiPageDownload = React.useCallback(async (config) => {
    const PAGE_SIZE = 50;
    let total = 0;
    let _pageIndexs = [];
    let results = [];
    let _config = {
      ...config,
    };
    delete _config.paging;

    try {
      let _firstTry = await JStorage.fetchDocuments(
        'user_profile',
        _config.query,
        _config.sort,
        {
          offset: 0,
          limit: 5,
        },
      );

      total = _firstTry.total;
      _pageIndexs = new Array(Math.ceil(total / PAGE_SIZE))
        .fill('')
        .map((i, idx) => idx);
    } catch (err) {
      alert('fail to get initial page data!');
      return [];
    }

    for (let idx of _pageIndexs) {
      try {
        let _pageData = await JStorage.fetchDocuments(
          'user_profile',
          _config.query,
          _config.sort,
          {
            offset: idx * PAGE_SIZE,
            limit: PAGE_SIZE,
          },
        );

        results.push(..._pageData.results);
      } catch (err) {
        //
        alert('fail to get page data!');
        console.log(idx, err);
        return [];
      }
    }

    return results;
  }, []);

  const _onDownload = React.useCallback(async () => {
    setLoading(true);

    function getKeywordQuery(keyword) {
      const searchArr = [];
      for (const field of ['owner', 'email', 'name', 'phone']) {
        searchArr.push({[field]: {$regex: `${keyword}`}});
      }
      return keyword
        ? {
            $or: searchArr,
          }
        : {};
    }

    let _activeFilterConfig = {
      query: {
        ...getKeywordQuery(queryState.keyword, queryState),
        ...queryState.filter,
        ...queryState.extraQueries,
      },
      sort: [queryState.sort],
    };

    let _data = await _multiPageDownload(_activeFilterConfig);

    _data.sort((a, b) => {
      return moment(b.created).utc() - moment(a.created).utc();
    });

    let _remapToDisplayData = _data.map(_remapOrderDataToDisplayString);
    setData(_remapToDisplayData);
    let _filename = `使用者清單.csv`;
    setCvsData({
      filename: _filename,
    });
  }, [_multiPageDownload, queryState]);

  return (
    <Wrapper style={style}>
      {Array.isArray(data) && data.length > 0 ? (
        <CSVLink
          ref={csvInstance}
          filename={cvsData.filename || '使用者清單'}
          headers={_headers}
          data={data}>
          <Button loading={loading} onClick={() => 0}>
            下載
          </Button>
        </CSVLink>
      ) : (
        <Button loading={loading} onClick={_onDownload}>
          下載
        </Button>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default UserExportCsvButton;
