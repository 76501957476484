import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {Helmet} from 'react-helmet';
import 'antd/dist/antd.less';
import './Layout.css';
import AdminLayout from './AdminLayout';
import ProfileLayout from './ProfileLayout';
import CheckoutLayout from './CheckoutLayout';
import SiteNavBar from './SiteNavBar';
import Footer from './Footer';
import {useOutlet} from 'reconnect.js';
import useModal from '../Hooks/useModal';

function loadGA() {
  function _loadGA(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);

    console.log('[rev][debug] GA loaded');
  }

  _loadGA(window, document, 'script', 'dataLayer', 'GTM-TJMQMW8');
}

function Layout({children, location}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [actions] = useOutlet('actions');
  const {modalElem, modalRef} = useModal();
  const opened = useRef(false);

  useEffect(() => {
    loadGA();
  }, [location]);

  useEffect(() => {
    if (location.pathname !== '/' || opened.current) {
      return;
    }
    async function fetchData() {
      try {
        actions.setLoading(true);
        let {results} = await actions.fetchDocuments('home', {}, ['-updated'], {
          limit: 1,
          offset: 0,
        });
        if (results.length === 0) {
          throw 'cannot get home data';
        }
      } catch (ex) {
      } finally {
        actions.setLoading(false);
      }
    }

    fetchData();
  }, [actions, location.pathname, modalRef]);

  useEffect(() => {
    const isAdmin = location.pathname.indexOf('admin') !== -1;

    const initialize = async (isAdmin) => {
      try {
        await actions.autoLogin({admin: isAdmin});
      } catch (ex) {
        console.log('autoLogin ex', ex);
        actions.logout();
      }

      setInitialized(true);
    };

    if (!initialized) {
      initialize(isAdmin);
    }
  }, [actions, location.pathname, initialized]);

  if (location.pathname.indexOf('admin') !== -1) {
    return <AdminLayout location={location}>{children}</AdminLayout>;
  } else if (location.pathname.indexOf('profile') > -1) {
    return (
      <Wrapper>
        <SiteNavBar />
        <ProfileLayout location={location}>{children}</ProfileLayout>
      </Wrapper>
    );
  } else if (location.pathname.indexOf('checkout') > -1) {
    return (
      <Wrapper>
        <SiteNavBar />
        <CheckoutLayout location={location}>{children}</CheckoutLayout>
      </Wrapper>
    );
  }

  let hasSiteNavBar = true;
  if (location.pathname.indexOf('/promo') === 0) {
    hasSiteNavBar = false;
  }

  return (
    <>
      <Helmet>
        <title>{`小和好點 dot.dot. bakery & cafe`}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta name="description" content="肉桂捲專門店 | 訂購 | 菜單 | 地址" />
        <meta name="author" content="RevtelTech 忻旅科技股份有限公司" />
        <meta
          name="google-site-verification"
          content="GnPZZKqDO6aBJadVLYrIaNRInquWqWgEZ2GUoF3FZx0"
        />
        <meta
          name="facebook-domain-verification"
          content="zjk0d06erncz3tkabsnm1ovzvw3k5f"
        />
      </Helmet>

      <iframe
        title="gtm-iframe"
        src="https://www.googletagmanager.com/ns.html?id=GTM-TJMQMW8"
        height="0"
        width="0"
        style={{display: 'none', visibility: 'hidden'}}></iframe>

      <Wrapper style={{filter: isModalOpen ? 'blur(10px)' : 'unset'}}>
        {hasSiteNavBar && <SiteNavBar />}
        <div style={{flex: 1}}>{children}</div>
        <Footer />
        {modalElem}
      </Wrapper>
    </>
  );
}

const ani = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to{
    opacity: 0;
    visibility: hidden;
  }
`;

const Wrapper = styled.div`
  --contentMaxWith: 1200px;
  --topNavBarHeight: 80px;
  --basePadding: 15px 20px;
  --baseContentPadding: 20px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default Layout;
