import React from 'react';
import styled from 'styled-components';
import {LogoFacebook} from '@styled-icons/ionicons-solid/LogoFacebook';
import {LogoInstagram} from '@styled-icons/ionicons-solid/LogoInstagram';
import {LINKS} from '../Domain/Constants';

function Footer(props) {
  return (
    <Wrapper>
      <div className="container">
        <div className="title">{`dot.dot. Bakery & Cafe`}</div>
        <div className="info">
          <div className="text">Follow us</div>
          <div className="socials">
            <a
              className="social"
              href={LINKS.instagram}
              target={'_blank'}
              rel="noreferrer">
              <LogoInstagram color={'#000'} size={24} />
            </a>
            <a
              className="social"
              style={{marginLeft: 20}}
              href={LINKS.facebook}
              target={'_blank'}
              rel="noreferrer">
              <LogoFacebook color={'#000'} size={24} />
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px var(--baseContentPadding);
    max-width: var(--contentMaxWith);
    margin: 0 auto;

    & > .title {
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      line-height: 19px;
      font-weight: bold;
    }
    & > .info {
      display: flex;
      align-items: center;

      & > .text {
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        line-height: 19px;
        margin-right: 30px;
      }
      & > .socials {
        & > .social {
        }
        & > .social:hover {
          opacity: 0.6;
        }
      }
    }
  }
`;

export default Footer;
