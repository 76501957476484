const BREAK_POINTS = {
  // desktop first, included. ex, 1300 -> desktop | 768 -> tablet
  desktop: 1400,
  tablet: 768,
  mobile: 600,
  xxs: 400,
};

const CART_ITEMS_LIMIT = 20;

const LINKS = {
  facebook: 'https://www.facebook.com/dot.dot.bakery.cafe',
  instagram: 'https://www.instagram.com/peacefuldotdot_hualien',
};

const SHOP_INFO = {
  address: '974花蓮縣壽豐鄉平和一街119號',
};

const ARROW_TYPES = {
  next: 'next',
  prev: 'prev',
};

const PRODUCT_TYPE = {
  default: 'default',
  nonDate: 'non_date',
};

const LOGISTIC_TYPE = {
  icatFreeze: 'icat_freeze',
  postNormal: 'post_normal',
  selfPick: 'self_pick',
};

const LOGISTIC_TYPE_DISPLAY = {
  icat_freeze: '黑貓冷凍',
  post_normal: '郵局宅配',
  self_pick: '自行取貨',
};

const ORDER_STATUS = {
  waiting: 'waiting',
  done: 'done',
};

const ORDER_STATUS_DISPLAY = {
  waiting: '未處理',
  done: '已完成',
};

const PAYMENT_STATUS = {
  pending: 'pending',
  codeGenerated: 'code_generated',
  success: 'success',
  expired: 'expired',
};

const PAYMENT_STATUS_DISPLAY = {
  pending: '等待中',
  code_generated: '已取號',
  success: '付款成功',
  expired: '逾時未付',
};

const LOGISTIC_STATUS = {
  pending: 'pending',
  centerDelivered: 'center_delivered',
  inDelivery: 'in_delivery',
  delivered: 'delivered',
  error: 'error',
  exception: 'exception',
};

const LOGISTIC_STATUS_DISPLAY = {
  pending: '等待中',
  center_delivered: '商品已出貨',
  in_delivery: '運送中',
  exception: '其他',
  error: '地址不合法',
  delivered: '已送達',
};

const CATEGORY = {
  logistics: {
    key: 'logistics',
    display: '宅配',
    image:
      'https://dotdotbakery-revtel2-com-stg.s3.ap-northeast-1.amazonaws.com/section-1-1628732905',
    description: '宅配雪山肉桂捲、好點司康禮盒',
  },
  self_pick: {
    key: 'self_pick',
    display: '自取',
    image:
      'https://dotdotbakery-revtel2-com-stg.s3.ap-northeast-1.amazonaws.com/section-1-1628732905',
    description: '全部商品',
  },
  dot: {
    key: 'dot',
    display: 'dot.dot. LIFE',
    image:
      'https://dotdotbakery-revtel2-com-stg.s3.ap-northeast-1.amazonaws.com/section-1-1628732905',
    description: 'Designed by dot.dot. 美與好用的商品，讓日常變的更美好',
  },
};

const MEMBERSHIP = {
  normal: 'normal',
  vip: 'vip',
  vvip: 'vvip',
};

const MEMBERSHIP_DISPLAY = {
  normal: '一般會員',
  vip: 'VIP',
  vvip: 'VVIP',
};

export {
  BREAK_POINTS,
  SHOP_INFO,
  LINKS,
  PRODUCT_TYPE,
  ARROW_TYPES,
  LOGISTIC_TYPE,
  LOGISTIC_TYPE_DISPLAY,
  ORDER_STATUS,
  ORDER_STATUS_DISPLAY,
  PAYMENT_STATUS,
  PAYMENT_STATUS_DISPLAY,
  LOGISTIC_STATUS,
  LOGISTIC_STATUS_DISPLAY,
  CATEGORY,
  CART_ITEMS_LIMIT,
  MEMBERSHIP,
  MEMBERSHIP_DISPLAY,
};
